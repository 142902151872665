@media screen and (min-width: 768px) {
  .container-login {
    -webkit-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-right: 0;
    padding: 10px;
  }

  .width-login {
    width: 460px;
  }
  
}

.center-block-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.margin-auto {
  margin: auto;
}

.sub-title-login {
  color: #868E96;
}

.width-form {
  padding: 0 50px;
  margin: auto;
}

.form-control-login {
  border: 1px solid #ced4da;
}
