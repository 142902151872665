.new-post-element {
  background-color: #FFFFFF;
  min-height: 170px;
  padding: 20px 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}

.new-post-attachments-icon {
  background: none;
  color: #00DD89;
  font-size: 25px;
  cursor: pointer;
}

.new-post-text {
  border: 1px dotted #d9d9d9 !important;
  border-radius: 5px;
  margin-left: 10px;
  color: #2B2B2B;
  font-family: 'Montserrat', sans-serif;
  line-height: 14px;
  padding: 10px;
  resize: none;
  width: 100%;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.new-post-text::placeholder {
  color: rgba(0, 0, 0, 0.30);
}

.new-post-separator {
  width: 100%;
}

.new-post-characters-limit {
  color: #969696;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.new-post-button-publish {
  background-color: #25cd87;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.new-post-button-publish:disabled {
  background-color: #969696 !important;
  opacity: 1 !important;
  cursor: initial;
}

@media (min-width: 768px) {
  margin-top: -2rem;
}


.new-post-element {
  background-color: #FFFFFF;
  min-height: 170px;
  padding: 20px 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}

.new-post-element-mobile {
  margin-bottom: 25px;
}

.new-post-separator {
  width: 100%;
}

.new-post-characters-limit {
  color: #969696;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.new-post-button-publish {
  background-color: #25cd87;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
  text-transform: uppercase;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
}
.new-post-button-publish:disabled {
  background-color: #0da666 !important;
  border-color: #0da666 !important;
  opacity: 0.6 !important;
  color: white !important;
  cursor: initial;
}

.new-post-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

@media (min-width: 768px) {
  margin-top: -2rem;
}


.ant-mention-wrapper .ant-mention-editor {
  border: 1px dotted #d9d9d9 !important;
}

.container-avatar-textarea {
  display: flex;
}

.new-post-preview {
  font-size: 12px;
  color: red;
}
