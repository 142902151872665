@media (min-width: 1400px) {
  .container {
      max-width: 1380px;
  }
}

.wrapper {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.vh-100 {
    height: 100vh;
}

.main-content {
    background-image: url('../../static/img/bg_mobile.jpg');
    background-size: cover;
    background-position: center center;
    color: #fff;
    display: flex;
    align-items: center;
}

.main-content h1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.1em;
}

.main-content h1 .bold {
  font-weight: 900;
}

.main-content .main-description {
  font-size: 1.2rem;
  line-height: 1.2em;
}

.main-action {
  font-size: 1rem;
}

.main-action a {
  width: 100%;
}

.btn-primary-c {
  background-color: #00a96e !important;
  padding: 22px 50px !important;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #fff !important;
  box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.2) !important;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px !important;
  cursor: pointer !important;
}

.btn-primary-c:hover {
  background-color: #028054;
  color: #fff !important;
}

@media screen and (min-width: 768px) {
    .main-content {
        background-image: url('../../static/img/bg_desktop.jpg');
    }

    .main-content h1 {
      font-size: 3.4rem;
    }

    .main-content .main-description {
      font-size: 1.75rem;
    }

    .main-action a {
      width: auto;
      padding: 20px 70px;
    }
}

@media (max-height: 570px) and (max-width: 380px) {
  .main-content h1 {
    font-size: 1.6rem;
  }
}

@media (max-width: 380px) {
  .btn-primary-c {
    font-size: 0.8rem;
    padding: 22px 0px;
    width: 100%;
    margin: 0px !important;
    color: #fff !important;
}
}