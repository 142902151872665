.grey-gradient {
  background: -moz-linear-gradient(top, #f4f4f4 0%, rgba(177,204,226,0) 46%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f4f4f4 0%,rgba(177,204,226,0) 46%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f4f4f4 0%,rgba(177,204,226,0) 46%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f4f4', endColorstr='#007db9e8',GradientType=0 ); /* IE6-9 */
}

.perfiles-img {
  height: 480px;
  margin-bottom: 170px;
  background-size: cover;
  background-position: center center;
  background-image: url('../../static/img/perfiles.jpg');
}

.perfiles-img .circle {
  right: 7%;
  bottom: -10%;
  background-image: url('../../static/img/circulos_small.png');
  width: 246px;
  height: 246px;
}

.perfiles-img .perfil-box-content {
  bottom: -150px;
}

.perfiles-img .perfil-box-content .perfil-box {
  padding: 30px;
  min-height: 172px;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
}

.perfiles-img .perfil-box-content .perfil-box h5 {
  color: #354597;
  font-size: 1.1rem;
  font-weight: 700;
}

.perfiles-img .perfil-box-content .perfil-box.perfil-box-big {
  min-height: 210px;
  z-index: 2;
}

.home-carousel .carousel {
  padding-bottom: 60px;
}

.home-carousel .carousel-inner {
  overflow: initial !important;
}

.home-carousel .carousel-indicators {
  bottom: -60px;
}

.home-carousel .carousel-indicators li {
  background-color: #979797;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.home-carousel .carousel-indicators .active {
  background-color: #354597;
}

.home-carousel .carousel-caption {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
  bottom: -50px;
  font-size: 0.9rem;
  line-height: 1.4em;
}

.home-carousel .carousel-caption h5 {
  color: #354597;
  font-size: 1.1rem;
  font-weight: 700;
}

.home-carousel .carousel-caption p {
  margin-bottom: 1rem !important;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .grey-gradient {
    background: none;
  }
}