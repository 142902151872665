.form-control,
.form-control:hover,
.form-control:active,
.form-control:focus {
    outline: none;
    font-size: 0.9rem;
    line-height: 1.55em;
}

.btn-success-a {
    background-color: #00a96e;
    margin: 0px !important;
    box-shadow: 0 0 0 0 !important;
    font-weight: 600;
    color: #fff !important;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-success-a:hover {
  background-color: #047a51;
  margin: 0px !important;
  font-weight: 600;
  color: #FFF !important;
}