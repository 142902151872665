footer {
  background-color: #e5e5e5;
  font-size: 0.9rem;
  overflow: hidden;
}

footer .links-footer {
  font-size: 0.85rem;
  font-weight: 600;
}

a, a:hover{
  text-decoration: none;
  color: #000;
}

@media (min-width: 768px) {
  footer .links-footer {
    font-size: 0.9rem;
  }
}
