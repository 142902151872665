.separator {
  height: 100px;
}

.separator-line {
  background-color: #354597;
  width: 2px;
  height: 100%;
}

@media (min-width: 768px) {
  .separator {
    height: 190px;
  }
}