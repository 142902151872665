h2 {
  font-weight: 900;
  letter-spacing: 0.2em;
  font-size: 1.8em;
}

.section-iggy .square-img {
  background-image: url('../../static/img/iggy_celular.jpg');
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  padding-top: 100%;
}

.section-iggy .square-img .circle {
  background-image: url('../../static/img/circulos.png');
  width: 377px;
  height: 376px;
  bottom: -75%;
  right: -40%;
}

.circle {
  background-image: url('../../static/img/circulos.png');
  width: 377px;
  height: 376px;
}

@media (min-width: 768px) {
  h2 {
    letter-spacing: 0.3em;
    font-size: 2rem;
  }

  .section-iggy .square-img {
    padding-top: 0px;
    overflow: initial;
  }

  .section-iggy .square-img .circle {
    background-image: url('../../static/img/circulos_small.png');
    width: 246px;
    height: 246px;
    left: -32%;
    bottom: auto;
    top: -10%;
    z-index: 2;
  }

  .section-iggy .square-text {
    z-index: 3;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
}

@media (max-width: 380px) {
  h2 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.9rem;
  }

  p, h2 {
    margin-bottom: 1rem !important;
  }
}

@media (min-width: 1200px) {
  h2 {
    letter-spacing: 0.5em;
  }

  .section-iggy .square-img .circle {
    background-image: url('../../static/img/circulos.png');
    width: 377px;
    height: 376px;
    left: -32%;
    top: -15%;
  }
}