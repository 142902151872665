.sub-heading-big {
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 0.2em;
}

@media (min-width: 768px) {
  .sub-heading-big {
    font-size: 1.3rem;
    letter-spacing: 0.2em;
  }
}

@media (max-width: 991px) {
  .fluid-small {
      max-width: 100% !important;
  }
}
