.section-seguridad .square-img {
  background-image: url('../../static/img/seguridad.jpg');
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  padding-top: 100%;
}

.section-seguridad .square-img p {
  line-height: 1.7em;
}

@media (min-width: 768px) {
  .section-seguridad .square-img {
    padding-top: 0px;
    overflow: initial;
    min-height: 590px;
  }
  .section-seguridad .square-img .circle {
    background-image: url('../../static/img/circulos_small.png');
    width: 246px;
    height: 246px;
    left: -13%;
    bottom: auto;
    top: 55%;
    z-index: 2;
  }

  .section-seguridad .square-text {
    z-index: 3;
    box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
    background: #fff;
    margin-left: -10%;
  }
}