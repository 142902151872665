.post-element-container {
  background-color: white;
  border-radius: 3px;
  border-bottom: 1px solid #e6e6e6;
}

.post-element-header {
  padding-left: 16px;
  padding-top: 16px;
}

.post-element-footer {
  padding: 16px;
  align-items: center;
  align-content: center;
}

.post-element-footer-like {
  font-weight: 600;
  text-align: left;
}